const BANNER_STATUS = {
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    CRITICAL: 'critical',
};

module.exports = {
    BANNER_STATUS,
};
