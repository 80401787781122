import { useState, useEffect } from "react";
import axios from "../../../axiosInstance";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {
  Banner,
  Button,
  Card,
  DatePicker,
  Frame,
  Icon,
  Popover,
  TextField,
  Toast,
} from "@shopify/polaris";
import { CalendarMinor } from "@shopify/polaris-icons";

import { PolarisVizProvider, BarChart, LineChart } from "@shopify/polaris-viz";

import moment from "moment";

import { BANNER_STATUS } from "./constants";
import { constant } from "lodash";

function Customers() {
  const dateFromInit = "1/1/2023";
  const dateToInit = "1/1/2025";

  const reports = [
    {
      name: "total",
      caption: "Total Customers",
    },
    {
      name: "withAICTag",
      caption: "Customers with AIC Tag",
    },
    {
      name: "withoutAICTag",
      caption: "Customers without AIC Tag",
    },
    {
      name: "emailSubscribed",
      caption: "Email Subscribed",
    },
    {
      name: "smsSubscribed",
      caption: "SMS Subscribed",
    },
    {
      name: "withAICTagEmailSubscribed",
      caption: "With AIC Tag Email Subscribed",
    },
    {
      name: "withAICTagSmsSubscribed",
      caption: "With AIC Tag SMS Subscribed",
    },
    {
      name: "withoutAICTagEmailSubscribed",
      caption: "Without AIC Tag Email Subscribed",
    },
    {
      name: "withoutAICTagSmsSubscribed",
      caption: "Without AIC Tag SMS Subscribed",
    },
  ];

  const [reportsLine1, setReportsLine1] = useState([]);
  const [reportsLine2, setReportsLine2] = useState([]);
  const [reportsLine3, setReportsLine3] = useState([]);

  const [msg, setMsg] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [dateFromVisible, setDateFromVisible] = useState(false);
  const [selectedDateFrom, setSelectedDateFrom] = useState(
    new Date(dateFromInit)
  );
  const [{ fromMonth, fromYear }, setDateFrom] = useState({
    fromMonth: selectedDateFrom.getMonth(),
    fromYear: selectedDateFrom.getFullYear(),
  });

  const [dateToVisible, setDateToVisible] = useState(false);
  const [selectedDateTo, setSelectedDateTo] = useState(new Date(dateToInit));
  const [{ toMonth, toYear }, setDateTo] = useState({
    toMonth: selectedDateTo.getMonth(),
    toYear: selectedDateTo.getFullYear(),
  });

  const [stats, setStats] = useState({});
  const [records, setRecords] = useState({});

  const [showBanner, setShowBanner] = useState(false);
  const [bannerStatus, setBannerStatus] = useState("critical");
  const [bannerTitle, setBannerTitle] = useState("");
  const [bannerDescription, setBannerDescription] = useState("");

  const updateBanner = (status, title, description) => {
    setBannerStatus(status);
    setBannerTitle(title);
    setBannerDescription(description);
    setShowBanner(true);
  };

  const toggleToast = () => setShowToast((showToast) => !showToast);

  const [query, setQuery] = useState({
    dateFrom: dateFromInit,
    dateTo: dateToInit,
  });

  const queryParams = () => {
    const params = [];
    const keys = Object.keys(query);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (query[key] === undefined || query[key] === null) {
        continue;
      }

      if (typeof query[key] === "string" || query[key] instanceof String) {
        if (query[key].length === 0) {
          continue;
        }
      }

      if (key === "submissionEmail") {
        params.push(`search=${encodeURIComponent(query[key])}`);
      } else {
        params.push(`${key}=${encodeURIComponent(query[key])}`);
      }
    }

    return params;
  };

  const formattedValue = (date) => date.toISOString().slice(0, 10);

  const setProp = async (prop, data) => {
    const rec = records;

    rec[prop] = data ? data : [];
    rec[`${prop}Num`] = getRecordsNum(data);

    setRecords(rec);
  };

  function getRecordsNum(data) {
    if (data) {
      const total = data[data.length - 1].value;
      const incremental = data[data.length - 1].value - data[0].value;

      let num = total;
      if (total !== incremental) {
        return `${total.toLocaleString()} (${incremental.toLocaleString()})`;
      } else {
        return total.toLocaleString();
      }
    } else {
      return 0;
    }
  }

  const getRecordsByDate = async (prop, dateFrom, dateTo) => {
    const bannerTitle = "Report";

    const params = queryParams();
    let url = `/api/dashboard/customers/${prop}`;

    if (dateFrom && dateTo) {
      url = `${url}?dateFrom=${dateFrom}&dateTo=${dateTo}`;
    } else {
      url = `${url}?${params.join("&")}`;
    }

    try {
      const response = await axios.get(url);

      if (response && response.status === 200 && response.data) {
        const data = response.data;
        const { status, msg, data: chartData } = data;

        if (status) {
          setMsg(msg);
          setShowToast(true);
          setProp(prop, chartData);
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            bannerTitle,
            msg ? msg : `Error loading Records`
          );
          setProp(prop, []);
        }
      } else {
        setProp(prop, []);
        updateBanner(
          BANNER_STATUS.CRITICAL,
          bannerTitle,
          msg ? msg : `Error loading Records`
        );
      }
    } catch (error) {
      setProp(prop, []);

      const err = getError(error);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        bannerTitle,
        msg ? err : `Error loading Records`
      );
    }
  };

  useEffect(() => {
    const reportsLines = JSON.parse(JSON.stringify(reports));
    setReportsLine1(reportsLines.splice(0, 3));
    setReportsLine2(reportsLines.splice(0, 3));
    setReportsLine3(reportsLines);

    refresh();
  }, []);

  function refresh(dateFrom, dateTo) {
    for (let i = 0; i < reports.length; i++) {
      const { name } = reports[i];
      getRecordsByDate(name, dateFrom, dateTo);
    }
  }

  function clear() {
    setSelectedDateFrom(new Date(dateFromInit));
    setSelectedDateTo(new Date(dateToInit));

    const dateFrom = moment(new Date(dateFromInit)).utc().format("MM/DD/YYYY");
    const dateTo = moment(new Date(dateToInit)).utc().format("MM/DD/YYYY");

    setQuery({
      ...query,
      dateFrom,
      dateTo,
    });

    refresh(dateFrom, dateTo);
  }

  const exportData = async () => {
    const bannerTitle = "Report";

    const params = queryParams();
    const url = `/api/dashboard/customers/export?${params.join("&")}`;

    try {
      const response = await axios.get(url);

      if (response && response.status === 200 && response.data) {
        const data = response.data;
        const { status, msg } = data;

        if (status) {
          setMsg(msg);
          setShowToast(true);
        } else {
          updateBanner(
            BANNER_STATUS.CRITICAL,
            bannerTitle,
            msg ? msg : `Error loading Records`
          );
        }
      } else {
        updateBanner(
          BANNER_STATUS.CRITICAL,
          bannerTitle,
          msg ? msg : `Error loading Records`
        );
      }
    } catch (error) {
      const err = getError(error);

      updateBanner(
        BANNER_STATUS.CRITICAL,
        bannerTitle,
        msg ? err : `Error loading Records`
      );
    }
  };

  const getError = (error) => {
    console.log(error);

    let err = null;

    if (error?.response) {
      const { status, msg } = error.response.data;
      err = msg;
    } else if (error?.request) {
      err = "Request error";
    } else {
      err = error?.message || "";
    }

    return err;
  };

  function dateFromOnClose() {
    setDateFromVisible(false);
  }

  function fromDateMonthChanged(month, year) {
    setDateFrom({ fromMonth: month, fromYear: year });
  }

  function fromDateChanged({ end: date }) {
    setSelectedDateFrom(date);
    setDateFromVisible(false);

    setQuery({
      ...query,
      dateFrom: moment(date).utc().format("MM/DD/YYYY"),
    });
  }

  function dateToOnClose() {
    setDateToVisible(false);
  }

  function toDateMonthChanged(month, year) {
    setDateTo({ toMonth: month, toYear: year });
  }

  function toDateChanged({ end: date }) {
    setSelectedDateTo(date);
    setDateToVisible(false);

    setQuery({
      ...query,
      dateTo: moment(date).utc().format("MM/DD/YYYY"),
    });
  }

  return (
    <Frame>
      <Container className="p-4 m-0" style={{ maxWidth: "100%" }}>
        {showBanner && (
          <Row>
            <Col>
              <div
                style={{
                  paddingLeft: "10px",
                  paddingTop: "10px",
                  paddingRight: "10px",
                }}
              >
                <Banner
                  title={bannerTitle}
                  status={bannerStatus}
                  onDismiss={() => {
                    setShowBanner(false);
                  }}
                >
                  <p>{bannerDescription}</p>
                </Banner>
              </div>
            </Col>
          </Row>
        )}

        <Row className="mb-4">
          <Col>
            <div style={{ paddingBottom: "10px" }}>
              <label>Date From</label>
              <Popover
                active={dateFromVisible}
                autofocusTarget="none"
                preferredAlignment="left"
                fullWidth
                preferInputActivator={false}
                preferredPosition="below"
                preventCloseOnChildOverlayClick
                onClose={dateFromOnClose}
                activator={
                  <TextField
                    role="combobox"
                    label=""
                    prefix={<Icon source={CalendarMinor} />}
                    value={formattedValue(selectedDateFrom)}
                    onFocus={() => setDateFromVisible(true)}
                    autoComplete="off"
                  />
                }
              >
                <Card>
                  <DatePicker
                    month={fromMonth}
                    year={fromYear}
                    selected={selectedDateFrom}
                    onMonthChange={fromDateMonthChanged}
                    onChange={fromDateChanged}
                  />
                </Card>
              </Popover>
            </div>
          </Col>
          <Col>
            <div style={{ paddingBottom: "10px" }}>
              <label>Date To</label>
              <Popover
                active={dateToVisible}
                autofocusTarget="none"
                preferredAlignment="left"
                fullWidth
                preferInputActivator={false}
                preferredPosition="below"
                preventCloseOnChildOverlayClick
                onClose={dateToOnClose}
                activator={
                  <TextField
                    role="combobox"
                    label=""
                    prefix={<Icon source={CalendarMinor} />}
                    value={formattedValue(selectedDateTo)}
                    onFocus={() => setDateToVisible(true)}
                    autoComplete="off"
                  />
                }
              >
                <Card>
                  <DatePicker
                    month={toMonth}
                    year={toYear}
                    selected={selectedDateTo}
                    onMonthChange={toDateMonthChanged}
                    onChange={toDateChanged}
                  />
                </Card>
              </Popover>
            </div>
          </Col>
          <Col>
            <div style={{ height: "10%", marginTop: "23px" }}>
              <Button primary fullWidth onClick={refresh}>
                {" "}
                Refresh
              </Button>
            </div>
          </Col>
          <Col>
            <div style={{ height: "10%", marginTop: "23px" }}>
              <Button primary fullWidth onClick={clear}>
                {" "}
                Clear
              </Button>
            </div>
          </Col>
          <Col>
            <div style={{ height: "10%", marginTop: "23px" }}>
              <Button primary fullWidth onClick={exportData}>
                {" "}
                Export
              </Button>
            </div>
          </Col>
          <Col>&nbsp;</Col>
        </Row>

        <Row className="mb-4">
          {reportsLine1.map((item) => (
            <Col>
              <div className="stats-note">{item.caption}</div>
              <div className="stats-value">{records[`${item.name}Num`]}</div>
            </Col>
          ))}
        </Row>

        <Row className="mb-4">
          {reportsLine2.map((item) => (
            <Col>
              <div className="stats-note">{item.caption}</div>
              <div className="stats-value">{records[`${item.name}Num`]}</div>
            </Col>
          ))}
        </Row>

        <Row className="mb-4">
          {reportsLine3.map((item) => (
            <Col>
              <div className="stats-note">{item.caption}</div>
              <div className="stats-value">{records[`${item.name}Num`]}</div>
            </Col>
          ))}
        </Row>

        {records &&
          reports.map((item, index) => (
            <Row className="mb-4">
              <Col>
                <Card sectioned title={item.caption}>
                  <PolarisVizProvider
                    themes={{
                      Light: {
                        chartContainer: {
                          minHeight: 400,
                        },
                      },
                    }}
                  >
                    {records[item.name] && (
                      <LineChart
                        data={[
                          {
                            data: records[item.name],
                          },
                        ]}
                        theme={"Light"}
                      />
                    )}
                  </PolarisVizProvider>
                </Card>
              </Col>
            </Row>
          ))}
      </Container>

      {showToast && <Toast content={msg} onDismiss={toggleToast} />}
    </Frame>
  );
}

export default Customers;
